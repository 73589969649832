<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import Layout from "../../layouts/main.vue";
import Validate from "@/service/ValidService";
import { useRoute, useRouter } from "vue-router";
import { FormInstance } from "element-plus";
import MethodService from "../../service/MethodService";
import Data from "./dataFormSubDepartment";
import mushroom from "cem-primary-api";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const ruleFormRef = ref(FormInstance);
const validateFlag = ref(false);
const route = useRoute();
const router = useRouter();
const dataForm = reactive({ value: MethodService.copyObject(Data.dataForm) });
const rulesForm = reactive({
  name: [Validate.required, Validate.checkSpaceString],
});

const addNewPhongBan = (formEl) => {
  validateFlag.value = true;
  formEl.validate(async (valid) => {
    if (valid) {
      try {
        const sub_department_object = {
          ...dataForm.value,
          name: dataForm.value.name.replace(/\s+/g, " ").trim(),
        };
        const response = await mushroom.sub_department.createAsync(
          sub_department_object
        );
        console.log(response);
        toastr.success(t("toastr_add_sub_departmant_success"));
        router.push({ name: "ListPhongBan" });
      } catch (e) {
        if (e.detail === "Violate unique key constraint")
          toastr.error(t("toastr_duplicate_sub_department_existed"));
        else toastr.error(t("toastr_add_sub_departmant_fail"));
      }
    }
  });
};

const getPhongBanDetails = async () => {
  const id = route.params.id;
  if (id) {
    try {
      const response = await mushroom.sub_department.findByIdAsync({
        id: id,
      });
      if (response.result) {
        dataForm.value = response.result;
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }
};

const updatePhongBan = (formEl) => {
  validateFlag.value = true;
  formEl.validate(async (valid) => {
    if (valid) {
      const sub_department_object = {
        ...dataForm.value,
        name: dataForm.value.name.replace(/\s+/g, " ").trim(),
      };
      try {
        const response = await mushroom.sub_department.partialUpdateAsync(
          sub_department_object
        );
        if (response.result == sub_department_object.id) {
          toastr.success(t("toastr_update_sub_departmant_success"));
          router.push({ name: "ListPhongBan" });
        } else console.log(t("toastr_data_not_change"));
      } catch (e) {
        if (e.detail === "Violate unique key constraint")
          toastr.error(t("toastr_duplicate_sub_department_existed"));
        else toastr.error(t("toastr_add_sub_departmant_fail"));
      }
    }
  });
};
watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);
onMounted(() => {
  getPhongBanDetails();
});
</script>
<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 class="text-uppercase mb-0 guideTitle">
          {{
            route.params.id
              ? $t("t_update_sub_department")
              : $t("t_add_sub_department")
          }}
        </h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            class="form-sub-department"
            :rules="rulesForm"
            ref="ruleFormRef"
            :model="dataForm.value"
            label-width="200px"
          >
            <el-form-item :label="$t('t_sub_department_name')" prop="name">
              <el-input
                v-model="dataForm.value.name"
                :placeholder="$t('t_place_sub_department_name')"
                clearable
              />
            </el-form-item>
          </el-form>
          <div class="text-center">
            <button
              class="btn btn-soft-secondary btn-border mx-3"
              @click="router.go(-1)"
            >
              {{ $t("t-back") }}
            </button>
            <el-button
              type="button"
              style="height: 38px"
              class="btn btn-secondary btn-border"
              v-if="router.currentRoute.value.name == 'ThemMoiPhongBan'"
              @click="addNewPhongBan(ruleFormRef)"
            >
              {{ $t("t-add") }}
            </el-button>
            <el-button
              type="button"
              style="height: 38px"
              class="btn btn-secondary btn-border"
              v-else
              @click="updatePhongBan(ruleFormRef)"
            >
              {{ $t("t-update") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
